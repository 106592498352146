<template>
	<div class="pay_waitting">
		<div class="content">
			<div class="outer">
				<div class="inner"></div>
			</div>
		</div>
		<div class="kongbai"></div>
		<p class="txt" v-if="payStatus">支付中...</p>
		<div class="tip">
			<span v-if="payStatus">订单支付中，请不要退出或者刷新当前页面！</span>
			<span v-else>
				支付失败，<router-link to="/order_list?key=ALL">点击返回</router-link>
			</span>
		</div>
	</div>
</template>

<script>
import {orderCollectDetail} from "@/services/orderApi.js";

export default {
	name: 'PayWaitting',
	data () {
		return {
			payStatus: true,
			timer: null,
			count: 60
		}
	},
	created () {
		this.orderPay();
		this.countDown();
	},
	beforeDestroy() {
	  clearInterval(this.timer);
	},
	methods: {
		// 订单支付
		async orderPay () {
			try {
				const id = this.$route.query.pay_order_id;
				const data = await orderCollectDetail(id);
				if (data.code === 0) {
					const status = data.data.status;
					if (status === 1) {
						this.$router.replace('/order_list?key=ALL');
					} else  {
						if (this.count > 0) {
							setTimeout( () => {
								this.orderPay();
							}, 1000);
						}
					}
				}
			} catch (err) {
				throw err;
			}
		},
		
		countDown () {
			if (this.timer) {
			  clearInterval(this.timer);
			}
			this.timer = setInterval(() => {
			  if (this.count > 0) {
			    this.count--;
			  } else {
			    this.payStatus = false;
			    clearInterval(this.timer);
			    this.timer = null;
			  }
			}, 1000);
		}
	}
}
</script>

<style scoped lang="less">
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.pay_waitting {
	text-align: center;
	
	.kongbai {
		width: 100%;
		height: 4.5rem;
	}
	
	.txt {
		font-size: 0.5rem;
		font-weight: bold;
	}
	
	.tip {
		margin-top: 0.3rem;
		color: gray;
		font-size: 0.25rem;
	}
	
	.content {
		width: 3rem;
		height: 3rem;
		position: absolute;
		top: 10%;
		left: 50%;
		-webkit-transform: translate(-50%, -10%);
		transform: translate(-50%, -10%);
		display: flex;
		justify-content: center;
		align-items: center;
		
		.outer {
			width: 2rem;
			height: 2rem;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 100%;
			border: 2px solid #2196F3;
			border-top-color: transparent;
			border-bottom-color: transparent;
			-webkit-animation: 1.5s outer-ani ease-in-out 0.2s infinite;
			animation: 1.5s outer-ani ease-in-out 0.2s infinite;
		}
		
		.inner {
			width: 1rem;
			height: 1rem;
			border-radius: 100%;
			background-color: #2196F3;
			-webkit-animation: 1.5s inner-ani ease-in-out 0.2s infinite;
			animation: 1.5s inner-ani ease-in-out 0.2s infinite;
		}
	}
	
	@keyframes outer-ani{
		0% {
			transform: rotate(0deg);
		}
		50% {
			transform: rotate(180deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	@-webkit-keyframes outer-ani{
		0% {
			-webkit-transform: rotate(0deg);
		}
		50% {
			-webkit-transform: rotate(180deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
		}
	}
	@keyframes inner-ani {
		0% {
			transform: scale(0.1);
		}
		50% {
			transform: scale(1);
		}
		100% {
			transform: scale(0.1);
		}
	}
	@-webkit-keyframes innner-ani{
		0% {
			-webkit-transform: scale(0.1);
		}
		50% {
			-webkit-transform: scale(1);
		}
		100% {
			-webkit-transform: scale(0.1);
		}
	}
}
</style>
