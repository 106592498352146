var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pay_waitting" }, [
    _vm._m(0),
    _c("div", { staticClass: "kongbai" }),
    _vm.payStatus
      ? _c("p", { staticClass: "txt" }, [_vm._v("支付中...")])
      : _vm._e(),
    _c("div", { staticClass: "tip" }, [
      _vm.payStatus
        ? _c("span", [_vm._v("订单支付中，请不要退出或者刷新当前页面！")])
        : _c(
            "span",
            [
              _vm._v("\n\t\t\t支付失败，"),
              _c("router-link", { attrs: { to: "/order_list?key=ALL" } }, [
                _vm._v("点击返回"),
              ]),
            ],
            1
          ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "outer" }, [
        _c("div", { staticClass: "inner" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }